import { motion } from 'framer-motion';
import { ArrowRight, MousePointerClick, PhoneCall, Search, Store } from 'lucide-react';

const JourneyStep = ({ icon: Icon, label, delay, description, details }) => (
  <motion.div
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ delay, duration: 0.5 }}
    className="flex flex-col items-center text-center bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-[0_20px_60px_-15px_rgba(0,0,0,0.1)] hover:shadow-[0_30px_80px_-15px_rgba(0,0,0,0.15)] transition-all duration-300 border-2 border-gray-200"
    style={{
      WebkitBackdropFilter: 'blur(8px)',
    }}
  >
    <div className={`w-20 h-20 rounded-2xl flex items-center justify-center mb-6 shadow-inner ${
      label === "Search" ? "bg-gradient-to-br from-blue-50 to-blue-100 text-blue-600" :
      label === "Click" ? "bg-gradient-to-br from-red-50 to-red-100 text-red-600" :
      label === "Call" ? "bg-gradient-to-br from-green-50 to-green-100 text-green-600" :
      label === "Visit" ? "bg-gradient-to-br from-orange-50 to-orange-100 text-orange-600" :
      "bg-gradient-to-br from-primary-50 to-primary-100 text-primary"
    }`}>
      <Icon className="w-10 h-10" strokeWidth={1.5} />
    </div>
    <h3 className={`font-display font-semibold text-2xl mb-4 ${
      label === "Search" ? "text-blue-600" :
      label === "Click" ? "text-red-600" :
      label === "Call" ? "text-green-600" :
      label === "Visit" ? "text-orange-600" :
      "text-secondary"
    }`}>{label}</h3>
    {label === "Search" ? (
      <p className="text-secondary mb-4 leading-relaxed">
        We make sure your advertising sticks. <span className="font-semibold text-lg text-secondary">When someone goes searching</span>, whether they search for <span className="font-semibold text-lg text-secondary">your business, your category, or even the message</span> that got their attention, we ensure <span className="font-semibold text-lg text-secondary">you get found</span>.
      </p>
    ) : label === "Click" ? (
      <p className="text-secondary mb-4 leading-relaxed">
        Most companies focus on <span className="font-semibold text-lg text-secondary">getting traffic</span>—we focus on getting <span className="font-semibold text-lg text-secondary">the right people to click on YOUR site</span>. We put your business in front of people <span className="font-semibold text-lg text-secondary">actively searching for what you offer</span>, so your clicks come from real potential customers, not just random visitors.
      </p>
    ) : label === "Visit" ? (
      <p className="text-secondary mb-4 leading-relaxed">
        If getting people to <span className="font-semibold text-lg text-secondary">walk through your doors</span> is what really matters, we have you covered. We can <span className="font-semibold text-lg text-secondary">remind the right customers</span> where to find you—<span className="font-semibold text-lg text-secondary">when they're ready to visit</span>.
      </p>
    ) : label === "Call" ? (
      <p className="text-secondary mb-4 leading-relaxed">
        We fine-tune every campaign so that when someone <span className="font-semibold text-lg text-secondary">calls you, they already know they need what you offer</span>—and they're ready to talk business.
      </p>
    ) : (
      <p className="text-secondary mb-4">{description}</p>
    )}
  </motion.div>
);

export default function HeroSection({ onGetStarted }) {
  return (
    <section className="relative min-h-screen bg-gradient-to-b from-white to-gray-50 text-secondary overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-20 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center max-w-4xl mx-auto"
        >
          <h1 className="text-5xl sm:text-6xl md:text-7xl font-display leading-tight mb-8">
            <span className="block text-red-600 font-bold mb-8">Get More People</span>
            <span className="block text-secondary text-4xl sm:text-5xl md:text-6xl mb-8">
              <u>Click</u>, <u>Visit</u>, <u>Call</u>, and <u>Search</u>
            </span>
            <span className="block">
              <span className="text-secondary font-normal">to</span>{' '}
              <span className="text-red-600 font-bold">Your Business</span>
            </span>
          </h1>
          <p className="text-xl sm:text-2xl text-secondary mb-12 max-w-3xl mx-auto leading-relaxed tracking-wide">
            We connect your business to the right people and guide them to{' '}
            <span className="font-semibold text-red-600">Want</span> you,{' '}
            <span className="font-semibold text-red-600">Find</span> you, and{' '}
            <span className="font-semibold text-red-600">Choose</span> you.
          </p>
          <div className="flex items-center justify-center gap-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              onClick={onGetStarted}
              className="bg-red-600 text-white font-semibold py-5 px-10 rounded-2xl text-lg shadow-[0_20px_60px_-15px_rgba(220,38,38,0.5)] hover:shadow-[0_30px_80px_-15px_rgba(220,38,38,0.6)] transition-all duration-300 flex items-center justify-center gap-2"
            >
              Find Out How
              <ArrowRight className="w-5 h-5" strokeWidth={1.5} />
            </motion.button>
          </div>
        </motion.div>

        <div className="mt-24 grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
          <JourneyStep
            icon={MousePointerClick}
            label="Click"
            delay={0.2}
            description="Most companies focus on getting traffic—we focus on right people to click. We put your business in front of people actively searching for what you offer, so your clicks come from real potential customers, not just random visitors."
            details={[]}
          />
          <JourneyStep
            icon={Store}
            label="Visit"
            delay={0.4}
            description="If getting people to walk through your doors is what really matters, we have you covered. We can remind the right customers where to find you—when they're ready to visit."
            details={[]}
          />
          <JourneyStep
            icon={PhoneCall}
            label="Call"
            delay={0.6}
            description="We fine-tune every campaign so that when someone calls you, they already know they need what you offer—and they're ready to talk business."
            details={[]}
          />
          <JourneyStep
            icon={Search}
            label="Search"
            delay={0.8}
            description="We make sure your advertising sticks, so when someone goes searching, whether they search for your business, your category, or even the message that got their attention, we ensure you show up and get found."
            details={[]}
          />
        </div>
      </div>
    </section>
  );
}