import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/HeroSection';
import ScalableSection from '../components/ScalableSection';
import ResultsSection from '../components/ResultsSection';
import JourneySection from '../components/JourneySection';
import JotFormSection from '../components/JotFormSection';
import TestimonialsMarquee from '../components/TestimonialsMarquee';

export default function HomePage() {
  const contactFormRef = useRef(null);

  const scrollToContact = () => {
    contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Set this to true when you want to show testimonials
  const showTestimonials = false;

  return (
    <>
      <Helmet>
        {/* Snitcher analytics code */}
        <script>
          {`
            !function(s,n,i,t,c,h){s.SnitchObject=i;s[i]||(s[i]=function(){
            (s[i].q=s[i].q||[]).push(arguments)});s[i].l=+new Date;c=n.createElement(t);
            h=n.getElementsByTagName(t)[0];c.src='//snid.snitcher.com/8430762.js';
            h.parentNode.insertBefore(c,h)}(window,document,'snid','script');
                
            snid('verify', '8430762');
          `}
        </script>
        <title>C-Bus Media Group - Click, Visit, Call, and Search Advertising Company</title>
        <meta name="description" content="Looking for affordable advertising options for small businesses in Columbus? C-Bus Media Group offers proven marketing strategies for new small businesses, helping you get more customers with affordable marketing plans. Expert digital marketing tips for small business growth." />
        <meta name="keywords" content="affordable advertising options for small businesses, best way to advertise a small business locally, advertising help for small business owners, where to advertise my small business online, how to get more visibility for a small business, top-rated ad agencies for small businesses, marketing strategies for new small businesses, digital marketing tips for small business growth, affordable marketing plans for local businesses, social media advertising for small businesses, how to get more customers for a small business, best paid advertising options for small companies, local ad platforms for small business promotion, online advertising help for small businesses, where should I advertise my small business, low-budget advertising solutions for startups" />
        <link rel="canonical" href="https://www.cbusmediagroup.com/" />
        
        {/* Open Graph / Social Media Meta Tags */}
        <meta property="og:title" content="C-Bus Media Group - Affordable Small Business Advertising Solutions" />
        <meta property="og:description" content="Expert advertising help for small business owners in Columbus. Get more customers with proven digital marketing strategies and affordable advertising options." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.cbusmediagroup.com" />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="geo.region" content="US-OH" />
        <meta name="geo.placename" content="Columbus" />
      </Helmet>
      <HeroSection onGetStarted={scrollToContact} />
      <ScalableSection onGetStarted={scrollToContact} />
      <JourneySection onGetStarted={scrollToContact} />
      <div ref={contactFormRef}>
        <JotFormSection />
      </div>
      <ResultsSection onGetStarted={scrollToContact} />
      {showTestimonials && <TestimonialsMarquee />}
    </>
  );
}