import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import ContactSection from '../components/ContactSection';
import { event } from '../utils/analytics';

const ToolSection = ({ logo, title, subtitle, description }) => (
  <div className="py-8" onClick={() => {
    event({
      action: 'view',
      category: 'Tool',
      label: title
    });
  }}>
    <div>
      {logo === 'google' ? (
        <img 
          src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png" 
          alt="Google Ads Management for Small Businesses" 
          className="h-12 mb-6"
        />
      ) : logo === 'facebook' ? (
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/2021_Facebook_icon.svg/2048px-2021_Facebook_icon.svg.png" 
          alt="Facebook Advertising for Local Businesses" 
          className="h-12 mb-6"
        />
      ) : logo === 'instagram' ? (
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Instagram_logo.svg/2560px-Instagram_logo.svg.png" 
          alt="Instagram Marketing for Small Business" 
          className="h-12 mb-6"
        />
      ) : logo === 'youtube' ? (
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/YouTube_Logo_2017.svg/2560px-YouTube_Logo_2017.svg.png" 
          alt="YouTube Advertising for Local Companies" 
          className="h-8 mb-6"
        />
      ) : logo === 'target' ? (
        <div className="w-12 h-12 mb-6">
          <div className="w-12 h-12 rounded-full border-4 border-primary flex items-center justify-center">
            <div className="w-6 h-6 rounded-full bg-primary" />
          </div>
        </div>
      ) : logo === 'email' ? (
        <div className="w-12 h-12 mb-6 text-primary">
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <rect x="2" y="4" width="20" height="16" rx="2" />
            <path d="M22 4l-10 8L2 4" />
          </svg>
        </div>
      ) : logo === 'audio' ? (
        <div className="w-12 h-12 mb-6 text-primary">
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z" />
            <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
            <line x1="12" y1="19" x2="12" y2="23" />
            <line x1="8" y1="23" x2="16" y2="23" />
          </svg>
        </div>
      ) : null}
      <h3 className="text-2xl font-bold text-secondary mb-3">{title}</h3>
      {subtitle && <h4 className="text-xl font-bold text-secondary mb-3">{subtitle}</h4>}
      <p className="text-secondary">{description}</p>
    </div>
  </div>
);

export default function ToolsPage() {
  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>Small Business Marketing Tools & Services | C-Bus Media Group</title>
        <meta name="description" content="Discover affordable marketing tools and advertising solutions for your small business. From Google Ads to social media marketing, find the perfect tools to grow your local business in Columbus." />
        <meta name="keywords" content="small business marketing tools, advertising solutions Columbus, social media marketing small business, google ads for local business, facebook advertising help, instagram marketing strategy, email marketing services, local business advertising tools, digital marketing Columbus, affordable advertising options, marketing automation tools, local SEO services, content marketing tools, social media management, online advertising platforms, small business marketing software" />
        <link rel="canonical" href="https://www.cbusmediagroup.com/tools" />
        
        {/* Schema.org structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "Small Business Marketing Tools",
            "provider": {
              "@type": "LocalBusiness",
              "name": "C-Bus Media Group",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "4401 Carriage Hill Lane",
                "addressLocality": "Columbus",
                "addressRegion": "OH",
                "postalCode": "43220",
                "addressCountry": "US"
              }
            },
            "description": "Comprehensive marketing tools and advertising solutions for small businesses in Columbus.",
            "areaServed": {
              "@type": "City",
              "name": "Columbus"
            },
            "serviceType": "Digital Marketing"
          })}
        </script>

        {/* Open Graph Tags */}
        <meta property="og:title" content="Small Business Marketing Tools & Services | C-Bus Media Group" />
        <meta property="og:description" content="Find the perfect marketing tools and advertising solutions for your small business in Columbus. Expert help with Google Ads, social media, and more." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.cbusmediagroup.com/tools" />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Small Business Marketing Tools & Services" />
        <meta name="twitter:description" content="Find the perfect marketing tools and advertising solutions for your small business in Columbus." />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="geo.region" content="US-OH" />
        <meta name="geo.placename" content="Columbus" />
        <meta name="geo.position" content="39.9612;-82.9988" />
        <meta name="ICBM" content="39.9612, -82.9988" />
      </Helmet>

      <section className="pt-32 pb-20 bg-gradient-to-br from-gray-800 to-gray-900 text-white relative">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.8)), url("/assets/columbus-skyline.png")'
          }}
        />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl"
          >
            <h1 className="text-5xl sm:text-6xl font-bold mb-6">
              WE HELP COMPANIES LIKE YOURS ACQUIRE MORE CUSTOMERS
            </h1>
            <p className="text-xl text-gray-300 leading-relaxed">
              C-Bus Media Group is an award-winning advertising company based in Columbus, OH. We specialize in advertising and marketing strategies that turn into a Click, Visit, Call, or Search for your business. We use Digital and Traditional Media like Google Ads (SEO-PPC), Retargeting, Social Media, Broadcast and Audio Streaming. We understand the intricacies of every platform so we can deliver measurable results from strategy to execution.
            </p>
          </motion.div>
        </div>
      </section>

      <section className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-center mb-20 text-secondary">THE ADVERTISING TOOLS WE USE</h2>
          
          <div className="grid md:grid-cols-2 gap-x-16 gap-y-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <ToolSection
                logo="google"
                title="Google Ads Management"
                description="As a certified Google Partner, we manage your pay-per-click campaigns with precision and efficiency. From keyword targeting to real-time optimization, we help ensure your ads appear at the right time, in front of the right audience—maximizing ROI and driving real results."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              <ToolSection
                logo="target"
                title="Targeted Display"
                subtitle="Precision Audience Targeting"
                description="Our targeted display advertising helps you reach ideal customers using smart technology like geofencing, contextual targeting, and site retargeting. Whether you're looking to stay top-of-mind or drive action, we make sure your message lands exactly where it should."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <ToolSection
                logo="facebook"
                title="Facebook Advertising Services"
                description="As a Meta Business Partner, we create and manage powerful campaigns in Facebook, helping you connect with the people who matter most. From brand awareness to lead generation, we optimize every element for performance and impact."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              <ToolSection
                logo="email"
                title="Email Marketing"
                subtitle="Strategic Email Marketing Solutions"
                description="Cut through the noise with strategic email campaigns. We design, write, and manage email marketing efforts that nurture leads, increase engagement, and drive conversions—straight to your customers' inboxes."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <ToolSection
                logo="audio"
                title="Audio Advertising"
                subtitle="Multi-Platform Audio Solutions"
                description="Whether it's radio, streaming, or podcasts—audio is personal. We help you reach people during the moments they're most engaged: in the car, at the gym, or winding down at home. With targeting capabilities and creative messaging, we make sure your brand speaks directly to the right audience, right when they're ready to listen."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              <ToolSection
                logo="youtube"
                title="YouTube Advertising Specialist"
                description="YouTube is where people go to watch, learn, and decide. With over 1.5 billion unique monthly users, it's a must-have in any digital strategy. We build campaigns that tap into high-intent audiences with video ads that stop the scroll and spark interest—right before their next favorite video plays."
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="md:col-span-2 max-w-2xl mx-auto"
            >
              <ToolSection
                logo="instagram"
                title="Instagram Advertising Services"
                description="Instagram is all about connection—and that's where your brand can shine. From stories to reels to eye-catching carousels, we create campaigns that feel native to the platform while driving results. It's more than just impressions—it's about inspiring action and turning scrolls into sales."
              />
            </motion.div>
          </div>
        </div>
      </section>

      <ContactSection />
    </div>
  );
}