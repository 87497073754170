import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

export default function ScalableSection({ onGetStarted }) {
  return (
    <section className="bg-gradient-to-br from-blue-900/90 via-blue-800/90 to-blue-900/90 py-24 relative overflow-hidden">
      {/* Gradient Border */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800/20 via-blue-700/20 to-blue-600/20 opacity-80" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Left Column */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-8"
          >
            <h2 className="text-5xl sm:text-6xl font-display font-bold leading-tight tracking-wide text-white">
              Advertising that <span className="text-red-600">Converts</span> 
            </h2>
            <p className="text-xl text-blue-100 leading-relaxed tracking-wide">
              Experience advertising that guides your perfect buyer from Wanting YOU, to Choosing YOU.
            </p>
            <motion.button
              whileHover={{ scale: 1.02 }}
              onClick={onGetStarted}
              className="bg-red-600 text-white font-semibold py-5 px-10 rounded-2xl text-lg shadow-[0_20px_60px_-15px_rgba(220,38,38,0.5)] hover:shadow-[0_30px_80px_-15px_rgba(220,38,38,0.6)] transition-all duration-300 flex items-center justify-center gap-2 border-2 border-red-600"
            >
              Get Started Today
              <ArrowRight className="w-5 h-5" strokeWidth={1.5} />
            </motion.button>
          </motion.div>

          {/* Right Column */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="relative"
          >
            <div className="aspect-video bg-white/10 backdrop-blur-sm rounded-xl overflow-hidden shadow-2xl border-2 border-white/20">
              <img
                src="/assets/success.png"
                alt="Our Advantage"
                className="w-full h-full object-cover"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}