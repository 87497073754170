import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Users, Star, Clock, Award, Building, ArrowRight } from 'lucide-react';
import JotFormSection from '../components/JotFormSection';
import { event } from '../utils/analytics';

export default function TeamPage() {
  return (
    <div className="min-h-screen">
      <Helmet>
        {/* Snitcher analytics code */}
        <script>
          {`
            !function(s,n,i,t,c,h){s.SnitchObject=i;s[i]||(s[i]=function(){
            (s[i].q=s[i].q||[]).push(arguments)});s[i].l=+new Date;c=n.createElement(t);
            h=n.getElementsByTagName(t)[0];c.src='//snid.snitcher.com/8430762.js';
            h.parentNode.insertBefore(c,h)}(window,document,'snid','script');
                
            snid('verify', '8430762');
          `}
        </script>
        <title>Expert Marketing Team in Columbus | C-Bus Media Group</title>
        <meta name="description" content="Meet our experienced marketing team in Columbus. We specialize in helping small businesses grow through effective advertising strategies, personalized marketing solutions, and proven results." />
        <meta name="keywords" content="marketing team Columbus, advertising experts Ohio, small business marketing specialists, digital marketing professionals, local advertising team, marketing consultants Columbus, advertising agency staff, marketing strategy experts, social media specialists, PPC management team, SEO professionals Columbus, content marketing experts, local business marketing team, advertising professionals OH, digital marketing consultants" />
        <link rel="canonical" href="https://www.cbusmediagroup.com/team" />
        
        {/* Schema.org structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "C-Bus Media Group",
            "description": "Expert marketing team helping small businesses grow in Columbus, Ohio",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "4401 Carriage Hill Lane",
              "addressLocality": "Columbus",
              "addressRegion": "OH",
              "postalCode": "43220",
              "addressCountry": "US"
            },
            "employee": [
              {
                "@type": "Person",
                "name": "John Smith",
                "jobTitle": "General Manager"
              },
              {
                "@type": "Person",
                "name": "Sarah Johnson",
                "jobTitle": "Director of Sales"
              }
            ]
          })}
        </script>

        {/* Open Graph Tags */}
        <meta property="og:title" content="Expert Marketing Team in Columbus | C-Bus Media Group" />
        <meta property="og:description" content="Meet our experienced marketing team dedicated to helping small businesses grow in Columbus through effective advertising strategies." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.cbusmediagroup.com/team" />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Expert Marketing Team in Columbus" />
        <meta name="twitter:description" content="Meet our experienced marketing team dedicated to helping small businesses grow in Columbus." />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="geo.region" content="US-OH" />
        <meta name="geo.placename" content="Columbus" />
        <meta name="geo.position" content="39.9612;-82.9988" />
        <meta name="ICBM" content="39.9612, -82.9988" />
      </Helmet>

      {/* Hero Section */}
      <section className="h-[80vh] relative overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7)), url("/assets/whyus.png")'
          }}
        />
      </section>

      <section className="py-24 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
            onClick={() => {
              event({
                action: 'view',
                category: 'Section',
                label: 'Why Choose Us'
              });
            }}
          >
            <div className="inline-flex items-center gap-2 bg-red-100 text-red-600 px-6 py-3 rounded-xl mb-8">
              <Star className="w-5 h-5" />
              <span className="font-semibold">Why Us?</span>
            </div>
            <h2 className="text-4xl font-display font-bold text-secondary mb-6">
              Why Choose C-Bus Media Team?
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all border-2 border-gray-100"
            >
              <div className="flex items-center gap-4 mb-6">
                <div className="w-12 h-12 bg-red-100 rounded-xl flex items-center justify-center">
                  <Clock className="w-6 h-6 text-red-600" />
                </div>
                <h3 className="text-2xl font-bold text-secondary">Decades of Experience</h3>
              </div>
              <p className="text-secondary leading-relaxed">
                Many of us have been in advertising since the early 90's, bringing stability and deep industry knowledge to our Columbus, OH advertising company. We've seen it all and understand exactly what businesses in our area need from a marketing partner.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all border-2 border-gray-100"
            >
              <div className="flex items-center gap-4 mb-6">
                <div className="w-12 h-12 bg-red-100 rounded-xl flex items-center justify-center">
                  <Award className="w-6 h-6 text-red-600" />
                </div>
                <h3 className="text-2xl font-bold text-secondary">Comprehensive Expertise</h3>
              </div>
              <p className="text-secondary leading-relaxed">
                We've mastered various advertising mechanisms including Google Ads, Facebook Ads, Audio Ads, Search and more. Our well-rounded campaigns surround customers with clear, concise messaging across multiple channels.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all border-2 border-gray-100"
            >
              <div className="flex items-center gap-4 mb-6">
                <div className="w-12 h-12 bg-red-100 rounded-xl flex items-center justify-center">
                  <Building className="w-6 h-6 text-red-600" />
                </div>
                <h3 className="text-2xl font-bold text-secondary">Local & National Reach</h3>
              </div>
              <p className="text-secondary leading-relaxed">
                Our Columbus, OH advertising company has created successful ad campaigns and online marketing strategies for businesses both locally and across the country.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all border-2 border-gray-100"
            >
              <div className="flex items-center gap-4 mb-6">
                <div className="w-12 h-12 bg-red-100 rounded-xl flex items-center justify-center">
                  <Users className="w-6 h-6 text-red-600" />
                </div>
                <h3 className="text-2xl font-bold text-secondary">Client-Focused Approach</h3>
              </div>
              <p className="text-secondary leading-relaxed">
                Our customers keep coming back because we pride ourselves on customer service and strategic preparation. We treat your business as if it were our own, delivering personal attention and exceptional results.
              </p>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.5 }}
            className="mt-16 text-center"
          >
            <button className="bg-red-600 hover:bg-red-700 text-white font-semibold py-4 px-8 rounded-xl text-lg shadow-lg hover:shadow-xl transition-all flex items-center justify-center mx-auto gap-2">
              Start Your Journey With Us
              <ArrowRight className="w-5 h-5" />
            </button>
          </motion.div>
        </div>
      </section>

      <JotFormSection />
    </div>
  );
}