// Google Analytics 4 and Snitcher utility functions
export const GA_TRACKING_ID = 'G-ZWBT000F9H';
export const SNITCHER_ID = '8430762';

// Initialize Analytics
export const initGA = () => {
  if (typeof window !== 'undefined') {
    // Load GA4 script
    const gaScript = document.createElement('script');
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    gaScript.async = true;
    document.head.appendChild(gaScript);

    // Initialize GA4 dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(args);
    }
    gtag('js', new Date());
    gtag('config', GA_TRACKING_ID);

    // Make gtag available globally
    window.gtag = gtag;

    // Load Snitcher script
    const snitcherScript = document.createElement('script');
    snitcherScript.textContent = `
      !function(s,n,i,t,c,h){s.SnitchObject=i;s[i]||(s[i]=function(){
      (s[i].q=s[i].q||[]).push(arguments)});s[i].l=+new Date;c=n.createElement(t);
      h=n.getElementsByTagName(t)[0];c.src='//snid.snitcher.com/${SNITCHER_ID}.js';
      h.parentNode.insertBefore(c,h)}(window,document,'snid','script');
          
      snid('verify', '${SNITCHER_ID}');
    `;
    document.head.appendChild(snitcherScript);
  }
};

// Page view tracking
export const pageview = (url: string, title: string) => {
  // Track in GA4
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', 'page_view', {
      page_location: url,
      page_title: title
    });
  }

  // Track in Snitcher
  if (typeof window.snid !== 'undefined') {
    window.snid('track', 'pageview', {
      url,
      title
    });
  }
};

// Event tracking
export const event = ({ action, category, label, value }: {
  action: string;
  category: string;
  label?: string;
  value?: number;
}) => {
  // Track in GA4
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  }

  // Track in Snitcher
  if (typeof window.snid !== 'undefined') {
    window.snid('track', action, {
      category,
      label,
      value
    });
  }
};

// Form submission tracking
export const trackFormSubmission = (formName: string, success: boolean) => {
  // Track in both GA4 and Snitcher
  event({
    action: 'form_submission',
    category: formName,
    label: success ? 'success' : 'failure'
  });
};