import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { MapPin, Mail, Building } from 'lucide-react';
import JotFormSection from '../components/JotFormSection';

const ContactCard = ({ name, role, email, phone }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-200"
  >
    <div className="flex items-start gap-6">
      <div className="w-16 h-16 bg-blue-600/10 rounded-2xl flex items-center justify-center flex-shrink-0">
        <Building className="w-8 h-8 text-blue-600" />
      </div>
      <div className="min-w-0 flex-1">
        <h3 className="text-2xl font-bold text-secondary mb-2">{name}</h3>
        <p className="text-lg text-secondary mb-4">{role}</p>
        <div className="space-y-3">
          <a 
            href={`mailto:${email}`} 
            className="flex items-center gap-3 text-secondary hover:text-blue-600 transition-colors group"
          >
            <Mail className="w-5 h-5 flex-shrink-0" />
            <span className="truncate group-hover:text-blue-600 break-all">{email}</span>
          </a>
          <a 
            href={`tel:${phone.replace(/[^0-9]/g, '')}`} 
            className="flex items-center gap-3 text-secondary hover:text-blue-600 transition-colors"
          >
            <MapPin className="w-5 h-5 flex-shrink-0" />
            <span>{phone}</span>
          </a>
        </div>
      </div>
    </div>
  </motion.div>
);

export default function ContactPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white pt-24">
      <Helmet>
        {/* Snitcher analytics code */}
        <script>
          {`
            !function(s,n,i,t,c,h){s.SnitchObject=i;s[i]||(s[i]=function(){
            (s[i].q=s[i].q||[]).push(arguments)});s[i].l=+new Date;c=n.createElement(t);
            h=n.getElementsByTagName(t)[0];c.src='//snid.snitcher.com/8430762.js';
            h.parentNode.insertBefore(c,h)}(window,document,'snid','script');
                
            snid('verify', '8430762');
          `}
        </script>
        <title>Contact Our Marketing Experts | C-Bus Media Group</title>
        <meta name="description" content="Ready to grow your business? Contact our marketing experts in Columbus for personalized advertising solutions. Get help with Google Ads, social media, and local marketing strategies." />
        <meta name="keywords" content="contact marketing experts, advertising help Columbus, marketing consultation, small business advertising help, local marketing agency contact, digital marketing support, advertising agency Columbus, marketing team contact, business growth consultation, marketing strategy help, advertising solutions contact, marketing services inquiry, local business advertising contact, marketing professionals Columbus, advertising expertise contact" />
        <link rel="canonical" href="https://www.cbusmediagroup.com/contact" />
        
        {/* Schema.org structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact C-Bus Media Group",
            "description": "Contact our marketing experts for personalized advertising solutions",
            "mainEntity": {
              "@type": "LocalBusiness",
              "name": "C-Bus Media Group",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "4401 Carriage Hill Lane",
                "addressLocality": "Columbus",
                "addressRegion": "OH",
                "postalCode": "43220",
                "addressCountry": "US"
              },
              "telephone": "+16144512191",
              "openingHours": "Mo-Fr 09:00-17:00"
            }
          })}
        </script>

        {/* Open Graph Tags */}
        <meta property="og:title" content="Contact Our Marketing Experts | C-Bus Media Group" />
        <meta property="og:description" content="Get in touch with our marketing experts for personalized advertising solutions that grow your business." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.cbusmediagroup.com/contact" />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Our Marketing Experts" />
        <meta name="twitter:description" content="Get in touch with our marketing experts for personalized advertising solutions." />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="geo.region" content="US-OH" />
        <meta name="geo.placename" content="Columbus" />
        <meta name="geo.position" content="39.9612;-82.9988" />
        <meta name="ICBM" content="39.9612, -82.9988" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center max-w-3xl mx-auto mb-20">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-5xl font-display font-bold text-secondary mb-6"
          >
            Let's Connect
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl text-secondary"
          >
            Ready to get more Clicks, Visits, Calls, and Searches for your business?
          </motion.p>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-200 max-w-2xl mx-auto mb-20"
        >
          <div className="flex flex-col items-center text-center">
            <div className="w-16 h-16 bg-blue-600/10 rounded-2xl flex items-center justify-center mb-6">
              <MapPin className="w-8 h-8 text-blue-600" />
            </div>
            <div>
              <h3 className="text-2xl font-bold text-secondary mb-2">Our Office</h3>
              <p className="text-lg text-secondary mb-4">C-Bus Media Group</p>
              <div className="space-y-2 text-secondary">
                <p>4401 Carriage Hill Lane</p>
                <p>Columbus, OH 43220</p>
                <p className="mt-4">
                  <a href="tel:+16144512191" className="text-blue-600 hover:text-blue-700 transition-colors">
                    (614) 451-2191
                  </a>
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        <div className="mb-20">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl font-bold text-center mb-12"
          >
            Meet Our Leadership Team
          </motion.h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            <ContactCard
              name="Todd J. Markiewicz"
              role="President and General Manager"
              email="TMarkiewicz@cbusmediagroup.com"
              phone="(614) 273-2652"
            />
            <ContactCard
              name="Adam Maisano"
              role="Director of Sales"
              email="AMaisano@cbusmediagroup.com"
              phone="(614) 273-2623"
            />
          </div>
        </div>

        <JotFormSection />
      </div>
    </div>
  );
}