import { motion } from 'framer-motion';
import { ArrowRight, Target, Search, CheckCircle } from 'lucide-react';

const Step = ({ number, title, description, features, icon: Icon }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
    className="bg-white rounded-2xl p-8 shadow-[0_20px_60px_-15px_rgba(0,0,0,0.1)] hover:shadow-[0_30px_80px_-15px_rgba(0,0,0,0.15)] transition-all border-2 border-gray-200"
  >
    <div className="flex items-start gap-4 mb-6">
      <div className={`flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center ${
        number === 1 ? "bg-red-100 text-red-600" :
        number === 2 ? "bg-blue-100 text-blue-600" :
        "bg-green-100 text-green-600"
      }`}>
        <Icon className="w-6 h-6" />
      </div>
      <div>
        <div className={`text-sm font-semibold mb-2 ${
          number === 1 ? "text-red-600" :
          number === 2 ? "text-blue-600" :
          "text-green-600"
        }`}>Step {number}</div>
        <h3 className="text-2xl font-bold text-secondary mb-3">{title}</h3>
        <p className="text-secondary mb-6">{description}</p>
      </div>
    </div>
    <ul className="space-y-4">
      {features.map((feature, index) => (
        <motion.li
          key={index}
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.1 }}
          className="flex items-start gap-3"
        >
          <ArrowRight className={`w-5 h-5 flex-shrink-0 mt-1 ${
            number === 1 ? "text-red-400" :
            number === 2 ? "text-blue-400" :
            "text-green-400"
          }`} />
          <p className="text-secondary">{feature}</p>
        </motion.li>
      ))}
    </ul>
  </motion.div>
);

export default function JourneySection({ onGetStarted }) {
  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-secondary mb-12"
          >
            Get Wanted. Get Found. Get Chosen.
          </motion.h2>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="relative bg-gradient-to-br from-primary/5 to-primary/10 rounded-3xl p-10 shadow-lg border-2 border-gray-200"
          >
            {/* Decorative elements */}
            <div className="absolute -top-4 -left-4 w-8 h-8 bg-primary/10 rounded-full" />
            <div className="absolute -bottom-4 -right-4 w-8 h-8 bg-primary/10 rounded-full" />
            
            <p className="text-xl leading-relaxed tracking-wide">
              <span className="block text-red-600 font-light mb-6">The old way...</span>
              <span className="text-secondary italic">Most businesses sit back and wait for customers to find them.</span>
              
              <span className="block h-px w-24 bg-primary/20 mx-auto my-8" />
              
              <span className="block text-red-600 font-semibold mb-6">The C-Bus Media way...</span>
              <span className="text-secondary font-medium">You can create demand before the search even starts.</span>
              
              <span className="block h-px w-48 bg-gradient-to-r from-transparent via-primary/20 to-transparent mx-auto my-8" />
              
              <span className="block text-secondary text-2xl font-display">
                Winning new customers isn't just about showing up—
                <br />
                it's about making people{' '}
                <span className="text-red-600 font-bold">want</span> you,{' '}
                <span className="text-red-600 font-bold">find</span> you,{' '}
                and ultimately{' '}
                <span className="text-red-600 font-bold">choose</span> you.
              </span>
            </p>
          </motion.div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <Step
            number={1}
            title="Get Wanted"
            description="Inspire People to Search for You"
            icon={Target}
            features={[
              "Put yourself on their radar by emotionally connecting with them.",
              "Show them why you're the best option—whether it's convenience, quality, or expertise",
              "Inspire them to search for you by creating an ad experience that sticks with them",
              "When you do this right, people won't just search for a category—they'll search for YOU"
            ]}
          />

          <Step
            number={2}
            title="Get Found"
            description="Be There When They Look"
            icon={Search}
            features={[
              "Be where they're looking—Google Search, Maps, and local directories",
              "Make your message match what made them search for you in the first place",
              "Focus on real buyers—not just anyone searching, but the people most likely to become paying customers",
              "Don't do all the work to make people search, just to hand them to competitors"
            ]}
          />

          <Step
            number={3}
            title="Get Chosen"
            description="Make Saying 'Yes' Easy"
            icon={CheckCircle}
            features={[
              "Capture interest through website clicks, business visits, calls, and repeat searches",
              "Stay in front of them and remind them why they searched for you",
              "Use direct, persuasive invitations that keep you top-of-mind",
              "Engage where it counts—on their devices, so they can take action instantly"
            ]}
          />
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.4 }}
          className="mt-16 text-center"
        >
          <button
            onClick={onGetStarted}
            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-4 px-8 rounded-xl text-lg transition-all transform hover:scale-105 flex items-center justify-center mx-auto gap-2 border-2 border-red-600"
          >
            Start Your Journey Today
            <ArrowRight className="w-5 h-5" />
          </button>
        </motion.div>
      </div>
    </section>
  );
}