import { motion } from 'framer-motion';
import { ArrowRight, TrendingUp, PhoneCall, Users, MousePointerClick } from 'lucide-react';

const MetricCard = ({ icon: Icon, title, description, delay = 0 }) => (
  <motion.div
    initial={{ y: 20, opacity: 0 }}
    whileInView={{ y: 0, opacity: 1 }}
    viewport={{ once: true }}
    transition={{ delay, duration: 0.5 }}
    className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-shadow duration-300 border-2 border-gray-200"
  >
    <div className="flex items-center gap-4 mb-6">
      <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center">
        <Icon className="w-6 h-6 text-blue-600" strokeWidth={1.5} />
      </div>
      <h3 className="font-display text-xl font-semibold text-secondary">{title}</h3>
    </div>
    <p className="text-secondary leading-relaxed">{description}</p>
  </motion.div>
);

export default function ResultsSection({ onGetStarted }) {
  return (
    <section className="py-24 bg-white relative overflow-hidden">
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1557683316-973673baf926?auto=format&fit=crop&q=80&w=2000')] opacity-5 bg-cover bg-center" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-flex items-center gap-2 bg-blue-100 text-blue-600 px-6 py-3 rounded-xl mb-8 border-2 border-gray-200"
          >
            <span className="font-semibold">Real People</span>
            <span className="text-blue-300">·</span>
            <span>Real Results</span>
            <TrendingUp className="w-4 h-4" strokeWidth={1.5} />
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-display font-bold text-secondary mb-6"
          >
            <span className="block mb-4">See the Power of Connecting with the</span>
            <span className="block">
              <u>Right Customers</u> at the <u>Right Time</u>
            </span>
          </motion.h2>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          <MetricCard
            icon={MousePointerClick}
            title="More Searches"
            description="Strategic ad placement and compelling copy that finds and converts highly qualified searchers in Columbus and beyond."
            delay={0.2}
          />
          <MetricCard
            icon={PhoneCall}
            title="More Calls and Clicks"
            description="Optimized and powerful local advertising that drive phone calls and clicks to your website from customers who want what you can provide."
            delay={0.4}
          />
          <MetricCard
            icon={Users}
            title="More In-Person Visits"
            description="Smart retargeting that brings interested visitors back to your Central Ohio business when they're ready to buy."
            delay={0.6}
          />
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.4 }}
          className="text-center"
        >
          <button
            onClick={onGetStarted}
            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-5 px-10 rounded-xl text-lg shadow-[0_20px_60px_-15px_rgba(220,38,38,0.5)] hover:shadow-[0_30px_80px_-15px_rgba(220,38,38,0.6)] transition-all transform hover:scale-105 flex items-center justify-center mx-auto gap-2 border-2 border-red-600"
          >
            Get Your Business More Clicks, Visits, Calls, and Searches
            <ArrowRight className="w-5 h-5" strokeWidth={1.5} />
          </button>
        </motion.div>
      </div>
    </section>
  );
}