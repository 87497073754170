import { motion } from 'framer-motion';
import { MapPin, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-secondary text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center md:text-left"
          >
            <img 
              src="/assets/logo.png"
              alt="C-Bus Media Group" 
              className="h-16 mb-8 mx-auto md:mx-0 brightness-0 invert"
            />
            <div className="space-y-4">
              <div className="flex items-start gap-3">
                <MapPin className="w-6 h-6 flex-shrink-0 mt-1" />
                <div>
                  <p className="font-semibold">C-Bus Media Group</p>
                  <p>4401 Carriage Hill Lane</p>
                  <p>Columbus, OH 43220</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <Phone className="w-6 h-6 flex-shrink-0" />
                <a href="tel:+16144512191" className="hover:text-gray-200 transition-colors">
                  (614) 451-2191
                </a>
              </div>
            </div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-center md:text-right"
          >
            <h3 className="text-xl font-semibold mb-4">Connect With Us</h3>
            <p className="text-gray-200 mb-6">
              Let's discuss how we can help your business grow.
            </p>
            <Link 
              to="/contact" 
              className="bg-red-600 hover:bg-red-700 text-white font-semibold py-3 px-6 rounded-xl transition-colors inline-flex items-center gap-2"
            >
              Get Started Today
            </Link>
          </motion.div>
        </div>
        
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.4 }}
          className="mt-16 pt-8 border-t border-white/20 text-center text-sm text-gray-300"
        >
          © {new Date().getFullYear()} C-Bus Media Group. All rights reserved.
        </motion.div>
      </div>
    </footer>
  );
}