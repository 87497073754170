import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Phone } from 'lucide-react';
import InputMask from 'react-input-mask';
import { trackFormSubmission } from '../utils/analytics';

export default function JotFormSection() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    // Load JotForm scripts
    const scripts = [
      { src: 'https://cdn02.jotfor.ms/static/prototype.forms.js?v=3.3.62009' },
      { src: 'https://cdn03.jotfor.ms/static/jotform.forms.js?v=3.3.62009' },
      { src: 'https://cdn01.jotfor.ms/js/vendor/maskedinput_5.0.9.min.js?v=3.3.62009' }
    ];

    const loadScript = (src: string) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const loadAllScripts = async () => {
      try {
        window.enableEventObserver = true;
        for (const script of scripts) {
          await loadScript(script.src);
        }
        setIsScriptLoaded(true);
      } catch (error) {
        console.error('Error loading JotForm scripts:', error);
      }
    };

    loadAllScripts();

    return () => {
      // Cleanup scripts on unmount
      scripts.forEach(script => {
        const element = document.querySelector(`script[src="${script.src}"]`);
        if (element) {
          element.remove();
        }
      });
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      // Initialize JotForm
      window.JotForm = {
        ...window.JotForm,
        init: function() {
          // Custom initialization if needed
        },
        onSubmitted: function() {
          setIsSubmitted(true);
          trackFormSubmission('contact_form', true);
        }
      };
    }
  }, [isScriptLoaded]);

  if (isSubmitted) {
    return (
      <section className="py-24 bg-white relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="max-w-3xl mx-auto text-center">
            <div className="mb-8 flex justify-center">
              <div className="w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center">
                <Phone className="w-10 h-10 text-blue-600" />
              </div>
            </div>
            <h2 className="text-4xl font-display font-bold text-secondary mb-4">
              Thanks for reaching out!
            </h2>
            <p className="text-xl text-secondary">
              Don't go very far, someone will be calling you in just a few minutes.
            </p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-24 bg-white relative overflow-hidden">
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1553356084-58ef4a67b2a7?auto=format&fit=crop&q=80&w=2000')] opacity-5 bg-cover bg-center" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="max-w-3xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-display font-bold text-secondary">
              <span className="block mb-4">Let's Build a Plan to Get More</span>
              <span className="block">Clicks, Visits, Calls & Searches</span>
            </h2>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-2xl p-8 shadow-[0_20px_60px_-15px_rgba(0,0,0,0.1)] border border-gray-100"
          >
            {isScriptLoaded && (
              <form
                className="jotform-form"
                action="https://submit.jotform.com/submit/250856380672160"
                method="post"
                name="form_250856380672160"
                id="250856380672160"
                acceptCharset="utf-8"
                autoComplete="on"
                onSubmit={(e) => {
                  e.preventDefault();
                  // Handle form submission
                  const formData = new FormData(e.currentTarget);
                  // Ensure phone number is in correct format
                  formData.set('q7_phoneNumber[full]', phoneNumber);
                  
                  fetch(e.currentTarget.action, {
                    method: 'POST',
                    body: formData,
                  })
                    .then(() => {
                      setIsSubmitted(true);
                      trackFormSubmission('contact_form', true);
                    })
                    .catch((error) => {
                      console.error('Form submission error:', error);
                      trackFormSubmission('contact_form', false);
                    });
                }}
              >
                <input type="hidden" name="formID" value="250856380672160" />
                <div className="space-y-6">
                  <div className="grid md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="input_1" className="block text-sm font-medium text-secondary mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        id="input_1"
                        name="q1_name"
                        required
                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/20 transition-shadow duration-200"
                        placeholder="John Smith"
                      />
                    </div>
                    <div>
                      <label htmlFor="input_2" className="block text-sm font-medium text-secondary mb-2">
                        Business Name
                      </label>
                      <input
                        type="text"
                        id="input_2"
                        name="q2_businessName"
                        required
                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/20 transition-shadow duration-200"
                        placeholder="Your Business Name"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="input_3" className="block text-sm font-medium text-secondary mb-2">
                      Who do you need to Click, Visit, Call, and Search your business?
                    </label>
                    <textarea
                      id="input_3"
                      name="q3_whoDo"
                      required
                      rows={4}
                      className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/20 transition-shadow duration-200"
                      placeholder="Describe the potential Customers, Clients, Patients, and Prospects that will help your business grow"
                    />
                  </div>

                  <div className="grid md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="input_7_full" className="block text-sm font-medium text-secondary mb-2">
                        Phone
                      </label>
                      <InputMask
                        mask="(999) 999-9999"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        id="input_7_full"
                        name="q7_phoneNumber[full]"
                        required
                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/20 transition-shadow duration-200"
                        placeholder="(614) 555-5555"
                      />
                    </div>
                    <div>
                      <label htmlFor="input_5" className="block text-sm font-medium text-secondary mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        id="input_5"
                        name="q5_email"
                        required
                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/20 transition-shadow duration-200"
                        placeholder="john@example.com"
                      />
                    </div>
                  </div>
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  type="submit"
                  className="mt-8 w-full bg-red-600 text-white font-semibold py-4 px-8 rounded-xl text-lg shadow-lg hover:shadow-xl transition-all flex items-center justify-center gap-2"
                >
                  Let's Talk
                </motion.button>
              </form>
            )}
          </motion.div>
        </div>
      </div>
    </section>
  );
}